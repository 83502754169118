<template>
  <mobile-screen :header="true" screen-class="profile-screen gray-bg icon-app1">
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="connectors-header icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: subdomain
                ? 'r_connector-types-company'
                : 'r_connector-types'
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div
          class="component-title"
          v-if="selectedConnectorType && selectedConnectorType.length"
        >
          {{ selectedConnectorType[0].name }}
        </div>
      </top-header-menu-wrapper>
      <section class="search-wrapper connectors-search">
        <div class="search-field">
          <input
            type="text"
            @keyup="searchQuery = $event.target.value"
            :placeholder="
              displayLabelName('connectors', 'connectors', 'search')
            "
          />
          <div class="icon search">
            <icon icon="#cx-app1-search-16x16" width="16" height="16" />
          </div>
        </div>
      </section>
    </template>
    <ul
      class="clebex-item-section"
      v-if="listOfConnectors && listOfConnectors.length"
    >
      <li
        class="clebex-item-section-item"
        v-for="conn in listOfConnectors"
        :key="conn.id"
      >
        <button
          class="clebex-item-content-wrapper"
          @click="selectConnector(conn.id)"
        >
          <span class="label">{{ conn.name }}</span>
          <span class="follow-up-icons">
            <span class="follow-up-icon-item">
              <icon
                v-if="conn.id === selectedConnectorId"
                icon="#cx-app1-checkmark"
              />
              <icon
                v-else
                icon="#cx-app1-arrow-right-12x12"
                height="12"
                width="12"
              />
            </span>
          </span>
        </button>
      </li>
    </ul>
  </mobile-screen>
  <router-view></router-view>
</template>

<script>
import { mapActions, mapState } from "vuex";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { getSubdomain } from "@/services/http-service";

export default {
  name: "Connectors",
  data() {
    return {
      selectedConnectorId: null,
      processing: false,
      searchQuery: "",
      subdomain: null
    };
  },
  created() {
    this.subdomain = getSubdomain();
  },
  mounted() {
    if (!this.selectedConnectorType) {
      this.$router.push({
        name: this.subdomain ? "r_connector-types-company" : "r_connector-types"
      });
    }
  },
  computed: {
    ...mapState("connector", ["connectors", "selectedConnectorType"]),
    listOfConnectors() {
      const query = this.searchQuery;
      if (
        this.connectors &&
        this.connectors.data &&
        this.connectors.data.length
      ) {
        if (query && query.length > 1) {
          return this.connectors.data.filter(item =>
            item.name.toLowerCase().includes(query.toLowerCase())
          );
        } else {
          return this.connectors.data;
        }
      }
      return null;
    }
  },
  methods: {
    ...mapActions("connector", ["setSelectedConnector"]),
    selectConnector(id) {
      this.selectedConnectorId = id;
      this.editConnector();
    },
    editConnector() {
      this.setSelectedConnector(
        this.connectors.data.filter(
          item => item.id === this.selectedConnectorId
        )
      );
      this.$router.push({
        name: this.subdomain ? "r_connector-company" : "r_connector"
      });
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  }
};
</script>
